import './components/bitstring.registration.js';
import './components/button.registration.js';
import './components/buttons.registration.js';
import './components/chart.registration.js';
import './components/clock.registration.js';
import './components/colorpicker.registration.js';
import './components/combobox.registration.js';
import './components/confirmbutton.registration.js';
import './components/container.registration.js';
import './components/crossover.registration.js';
import './components/dialog.registration.js';
import './components/drag3d.registration.js';
import './components/dynamics.registration.js';
import './components/equalizer.registration.js';
import './components/expand.registration.js';
import './components/fader.registration.js';
import './components/fileselect.registration.js';
import './components/frame.registration.js';
import './components/frequencyresponse.registration.js';
import './components/gauge.registration.js';
import './components/icon.registration.js';
import './components/knob.registration.js';
import './components/label.registration.js';
import './components/levelmeter.registration.js';
import './components/marquee.registration.js';
import './components/meter.registration.js';
import './components/multimeter.registration.js';
import './components/navigation.registration.js';
import './components/notifications.registration.js';
import './components/options.registration.js';
import './components/pager.registration.js';
import './components/pages.registration.js';
import './components/panorama.registration.js';
import './components/phasemeter.registration.js';
import './components/progressbar.registration.js';
import './components/root.registration.js';
import './components/reverb.registration.js';
import './components/scale.registration.js';
import './components/scrollarea.registration.js';
import './components/scroller.registration.js';
import './components/select.registration.js';
import './components/slider.registration.js';
import './components/spread.registration.js';
import './components/state.registration.js';
import './components/toggle.registration.js';
import './components/value.registration.js';
import './components/valuebutton.registration.js';
import './components/valueknob.registration.js';
import './components/widget.registration.js';
