import './object_controls/OcaPanBalance.js';
import './object_controls/OcaBooleanActuator.js';
import './object_controls/OcaIntActuator.js';
import './object_controls/OcaFloatActuator.js';
import './object_controls/OcaBitstringActuator.js';
import './object_controls/OcaStringActuator.js';
import './object_controls/OcaFreqActuator.js';
import './object_controls/OcaMute.js';
import './object_controls/OcaPolarity.js';
import './object_controls/OcaSwitch.js';
import './object_controls/OcaGain.js';
import './object_controls/OcaDelay.js';
import './object_controls/OcaFilterClassical.js';
import './object_controls/OcaFilterFIR.js';
import './object_controls/OcaFilterParametric.js';
import './object_controls/OcaDynamics.js';
import './object_controls/OcaDynamicsDetector.js';
import './object_controls/OcaSignalGenerator.js';
import './object_controls/OcaTemperatureActuator.js';
import './object_controls/OcaIdentificationActuator.js';

import './object_controls/OcaIntSensor.js';
import './object_controls/OcaFloatSensor.js';
import './object_controls/OcaBooleanSensor.js';
import './object_controls/OcaStringSensor.js';
import './object_controls/OcaBitstringSensor.js';
import './object_controls/OcaLevelSensor.js';
import './object_controls/OcaTimeIntervalSensor.js';
import './object_controls/OcaFrequencySensor.js';
import './object_controls/OcaTemperatureSensor.js';
import './object_controls/OcaVoltageSensor.js';
import './object_controls/OcaCurrentSensor.js';
import './object_controls/OcaGainSensor.js';
import './object_controls/OcaImpedanceSensor.js';

import './object_details/OcaDeviceManager.js';
import './object_details/OcaBlock.js';
import './object_details/OcaWorker.js';
import './object_details/OcaPanBalance.js';
import './object_details/OcaIntActuator.js';
import './object_details/OcaFloatActuator.js';
import './object_details/OcaFreqActuator.js';
import './object_details/OcaBooleanActuator.js';
import './object_details/OcaBooleanExtendedActuator.js';
import './object_details/OcaBitstringActuator.js';
import './object_details/OcaStringActuator.js';
import './object_details/OcaGain.js';
import './object_details/OcaDelay.js';
import './object_details/OcaSwitch.js';
import './object_details/OcaFilterClassical.js';
import './object_details/OcaFilterFIR.js';
import './object_details/OcaFilterParametric.js';
import './object_details/OcaDynamics.js';
import './object_details/OcaDynamicsDetector.js';
import './object_details/OcaSignalGenerator.js';
import './object_details/OcaTemperatureActuator.js';
import './object_details/OcaIdentificationActuator.js';

import './object_details/OcaBooleanSensor.js';
import './object_details/OcaIntSensor.js';
import './object_details/OcaFloatSensor.js';
import './object_details/OcaStringSensor.js';
import './object_details/OcaBitstringSensor.js';
import './object_details/OcaLevelSensor.js';
import './object_details/OcaTimeIntervalSensor.js';
import './object_details/OcaFrequencySensor.js';
import './object_details/OcaTemperatureSensor.js';
import './object_details/OcaVoltageSensor.js';
import './object_details/OcaCurrentSensor.js';
import './object_details/OcaGainSensor.js';
import './object_details/OcaImpedanceSensor.js';

import './device_controls/Generic.js';

import '../pool/index.js';
